import { isNumeric } from '@nestoca/ui';

import {
    ApplicationType,
    APPLICATION_TYPE,
    APPLICATION_TYPES,
    MAIN_APPLICATION_TYPE,
} from 'types/application';

import { allowValue } from './fns';
import { truncateToTwoDecimals } from './math';
import { booleanNormalizer } from './normalizer';

export const allowedRateQueries = [
    'context',
    'additionalFundAmount',
    'amortization',
    'creditScore',
    'daysToClose',
    'downPaymentAmount',
    'helocOption',
    'lenders',
    'limit',
    'mortgageBalance',
    'page',
    'postalCode',
    'propertyValue',
    'purpose',
    'regionCode',
    'term',
    'transactionType',
    'type',
    'rateHold',
    'originallyInsured',
    'productName',
    'investors',
    'productFamilies',
    'isTransferFeeAdded',
];

export const allowedHelocRateQueries = [
    'context',
    'ltvCreditLimit',
    'ltvCombined',
    'creditLimit',
    'isStandalone',
    'gds',
    'tds',
    'creditScore',
    'lenders',
    'postalCode',
    'purpose',
    'regionCode',
    'transactionType',
];

const allowedLimit = [5, 10, 20, 50] as const;
const allowedHelocOption = ['HELOC_WITH', 'HELOC_WITHOUT'] as const;
export const allowedPurpose = [
    'OWNER_OCCUPIED',
    'ANY_RENTAL',
    'RENTAL_1_UNIT',
    'RENTAL_2-4_UNITS',
] as const;
const allowedRegionCode = [
    'ON',
    'QC',
    'AB',
    'BC',
    'MB',
    'NB',
    'NS',
    'NL',
    'PE',
    'SK',
] as const;
const allowedTerm = [
    '6_MONTHS',
    '1_YEAR',
    '2_YEAR',
    '3_YEAR',
    '4_YEAR',
    '5_YEAR',
    '6_YEAR',
    '7_YEAR',
    '10_YEAR',
    'HELOC',
] as const;
const allowedTransactionType = APPLICATION_TYPES;
const allowedType = ['FIXED', 'VARIABLE'] as const;
const allowedRateHold = [
    '30_DAYS',
    '45_DAYS',
    '60_DAYS',
    '90_DAYS',
    '110_DAYS',
    '120_DAYS',
    '150_DAYS',
    '180_DAYS',
] as const;
const allowedContext = ['TRANSACTION', 'SIMULATION', 'HELOC'] as const;

const allowedAmmortization = Array.from({ length: 35 }, (_, i) => i + 1);

const allowedOriginallyInsured = ['true', 'false'] as const;

// Get type fo each allowedXXX array const
export type AllowedLimit = (typeof allowedLimit)[number];
export type AllowedHelocOption = (typeof allowedHelocOption)[number];
export type AllowedPurpose = (typeof allowedPurpose)[number];
export type AllowedRegionCode = (typeof allowedRegionCode)[number];
export type AllowedTerm = (typeof allowedTerm)[number];
export type AllowedTransactionType = (typeof allowedTransactionType)[number];
export type AllowedType = (typeof allowedType)[number];
export type AllowedRateHold = (typeof allowedRateHold)[number];
export type AllowedContext = (typeof allowedContext)[number];
export type AllowedOriginallyInsured =
    (typeof allowedOriginallyInsured)[number];

// Sanitize Rate Queries from URL to formatted and validated
export const rateQueriesSanitize = {
    additionalFundAmount: (value?: string): number | undefined =>
        value ? +value : undefined,
    amortization: (value?: string): number | undefined =>
        allowValue<number>(allowedAmmortization, value ? +value : value, 25),
    creditScore: (value?: string): number | string | undefined =>
        value ? (+value === -1 ? '' : +value) : '',
    downPaymentAmount: (value?: string) => (value ? +value : value),
    helocOption: (value?: string) =>
        allowValue<AllowedHelocOption>(
            allowedHelocOption,
            value ? value.toUpperCase() : value
        ),
    lenders: (lenders?: any): number[] | undefined =>
        lenders && typeof lenders === 'string'
            ? lenders?.split(',').map((lender) => +lender)
            : lenders && Array.isArray(lenders)
              ? lenders
              : undefined,
    rateHold: (rateHold?: string): string[] | undefined =>
        rateHold && typeof rateHold === 'string'
            ? rateHold?.split(',')
            : rateHold && Array.isArray(rateHold)
              ? rateHold
              : [],
    mortgageBalance: (value?: string) => (value ? +value : value),
    limit: (value?: string) =>
        allowValue<AllowedLimit>(allowedLimit, +value, 50),
    page: (value?: string) => (value ? +value : 1),
    postalCode: (value?: string) => (value ? value.toUpperCase() : value),
    propertyValue: (value?: string) => (value ? +value : value),
    purpose: (value?: string) =>
        allowValue<AllowedPurpose>(
            allowedPurpose,
            value ? value.toUpperCase() : value
        ),
    regionCode: (value?: string) =>
        allowValue<AllowedRegionCode>(
            allowedRegionCode,
            value ? value.toUpperCase() : value
        ),
    term: (value?: string) =>
        allowValue<AllowedTerm>(
            allowedTerm,
            value ? value.toUpperCase() : value
        ),
    transactionType: (value?: string) =>
        allowValue<AllowedTransactionType>(
            allowedTransactionType,
            value ? value.toUpperCase() : value
        ),
    type: (value?: string) =>
        allowValue<AllowedType>(
            allowedType,
            value ? value.toUpperCase() : value
        ),
    investors: (investors?: any): number[] | undefined =>
        investors && typeof investors === 'string'
            ? investors?.split(',').map((investor) => +investor)
            : investors && Array.isArray(investors)
              ? investors
              : undefined,
    productFamilies: (productFamilies?: string): string[] | undefined =>
        productFamilies && typeof productFamilies === 'string'
            ? productFamilies?.split(',')
            : productFamilies && Array.isArray(productFamilies)
              ? productFamilies
              : [],
    isTransferFeeAdded: (value?: string | boolean) =>
        value === undefined || value === null
            ? undefined
            : String(booleanNormalizer(value)),

    context: (value?: string) =>
        allowValue<AllowedContext>(
            allowedContext,
            value ? value.toUpperCase() : value
        ),
    originallyInsured: (value?: string | boolean) =>
        allowValue<AllowedOriginallyInsured>(
            allowedOriginallyInsured,
            value ? String(booleanNormalizer(value)) : String(value)
        ),
};

export const helocRateQueriesSanitize = {
    creditLimit: (value?: string) => (value ? +value : value),
    isStandalone: (value?: string | boolean) =>
        value ? String(booleanNormalizer(value)) : true,
    creditScore: (value?: string): number | string | undefined =>
        value ? (+value === -1 ? '' : +value) : '',

    lenders: (lenders?: any): number[] | undefined =>
        lenders && typeof lenders === 'string'
            ? lenders?.split(',').map((lender) => +lender)
            : lenders && Array.isArray(lenders)
              ? lenders
              : undefined,
    postalCode: (value?: string) => (value ? value.toUpperCase() : value),
    purpose: (value?: string) =>
        allowValue<AllowedPurpose>(
            allowedPurpose,
            value ? value.toUpperCase() : value
        ),
    regionCode: (value?: string) =>
        allowValue<AllowedRegionCode>(
            allowedRegionCode,
            value ? value.toUpperCase() : value
        ),

    context: (value?: string) =>
        allowValue<AllowedContext>(
            allowedContext,
            value ? value.toUpperCase() : value
        ),
    transactionType: (value?: string) =>
        allowValue<AllowedTransactionType>(
            allowedTransactionType,
            value ? value.toUpperCase() : value
        ),
};

export const MINIMUM_PRODUCT_STRESS_RATE = 5.25;

export const getQualifyingRate = ({
    productRate,
    stressRate,
    qualifyingRate,
}: {
    productRate: number;
    stressRate: number;
    qualifyingRate: number;
}) => {
    const qualificationProductStressRate = productRate + stressRate;

    const trimmedQualificationProductStressRate = truncateToTwoDecimals(
        qualificationProductStressRate
    );

    if (trimmedQualificationProductStressRate < qualifyingRate) {
        return qualifyingRate;
    }

    return trimmedQualificationProductStressRate;
};

export const getHelocQualificationProductStressRate = ({
    productRate,
    stressRate,
}: {
    productRate: number;
    stressRate: number;
}) => {
    // Product rate = 3.7
    // 5.25 < (3.7+2) --> 5.7
    // product rate = 2.5
    // 5.25 > (2.5+2) --> use 5.25

    // HELOC qualification rate is always 5.25
    return getQualifyingRate({
        productRate,
        stressRate,
        qualifyingRate: MINIMUM_PRODUCT_STRESS_RATE,
    });
};

export const getNonHelocQualificationProductStressRate = ({
    productRate,
    stressRate,
    contractRate,
    applicationType,
    isInsuredProduct,
}: {
    productRate: number;
    stressRate: number;
    contractRate: number;
    applicationType: ApplicationType;
    isInsuredProduct: boolean;
}) => {
    // Product rate = 3.7
    // 5.25 < (3.7+2) --> 5.7
    // product rate = 2.5
    // 5.25 > (2.5+2) --> use 5.25

    // For renewal or transfer applications, use the contract rate
    // otherwise use MINIMUM_PRODUCT_STRESS_RATE
    const minQualifyingRate = getMinQualifyingRate(
        applicationType,
        contractRate,
        isInsuredProduct
    );

    return getQualifyingRate({
        productRate,
        stressRate,
        qualifyingRate: minQualifyingRate,
    });
};

export const getMinQualifyingRate = (
    applicationType: ApplicationType,
    contractRate: number,
    isInsuredProduct: boolean
) => {
    const isRenewalOrTransfer = (
        [
            MAIN_APPLICATION_TYPE.RENEWAL,
            APPLICATION_TYPE.TRANSFER,
        ] as ApplicationType[]
    ).includes(applicationType);

    const isInsuredRenewalOrTransfer = isRenewalOrTransfer && isInsuredProduct;

    const useContractRate =
        isInsuredRenewalOrTransfer && isNumeric(contractRate);

    // If the application is a insured renewal or transfer, use the contract rate
    // otherwise use MINIMUM_PRODUCT_STRESS_RATE
    const minQualifyingRate = useContractRate
        ? contractRate
        : MINIMUM_PRODUCT_STRESS_RATE;

    return minQualifyingRate;
};
