import { PropsWithChildren, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

type PortalProps = {
    id?: string;
};

export const Portal = ({ children, id }: PropsWithChildren<PortalProps>) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    return mounted
        ? createPortal(
              <div id={`modal-${id}`}>{children}</div>, // div wrapper element is kept here to support legacy portal code  with ID for cypress tests
              document.querySelector('#modal-root')
          )
        : null;
};

export default Portal;
