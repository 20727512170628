import { TenantSlug } from '@nestoca/multi-tenant';
import { atom, atomFamily } from 'recoil';

import { ApplicantDocument, DocumentFile } from 'types/documents';
import { Context } from 'types/rates';

import {
    defaultHelocRateFiltersSelector,
    defaultRateFiltersSelector,
} from './filters.selector';
import {
    getSingleRate,
    getSelectedRate,
    getSelectedHelocRate,
} from './selectors';

import type { SubPartnersCounts } from 'types/sub-partner';

export const currentRateInformation = atom({
    key: 'currentRateInformation',
    default: undefined,
});

export const selectedRateState = atomFamily({
    key: 'selectedRateState',
    default: getSelectedRate,
});

export const selectedHelocRateState = atomFamily({
    key: 'selectedHelocRateState',
    default: getSelectedHelocRate,
});

export const singleRateState = atomFamily({
    key: 'singleRateState',
    default: getSingleRate,
});

export const ocrState = atom({
    key: 'ocrState',
    default: undefined,
});

export const LightQualificationAddSectionState = atom({
    key: 'LightQualificationAddSectionState',
    default: undefined,
});

export const draggingDocId = atom<DocumentFile['fileId'] | undefined>({
    key: 'draggingDocId',
    default: undefined,
});

// export const insurerResponseState = atom<InsurerResponse>({
//     key: 'insurerResponseState',
//     default: undefined,
// default: {
//     messages: [
//         { code: '0003', text: 'MISSING LENDER REF. NUMBER' },
//         { code: '0018', text: 'INVALID/MISSING TOTAL QUALIFYING INCOME' },
//         { code: '0022', text: 'INVALID/MISSING SHELTER PAYMENT' },
//         { code: '0090', text: 'TOTAL UNITS EXCEEDS MAXIMUM' },
//         { code: '0095', text: "CALC'D LTV RATIO EXCEEDS MAXIMUM" },
//         { code: '0096', text: 'EQUITY + LOAN AMT MUST = LENDING VALUE' },
//         { code: '0101', text: 'MAX. ALLOWABLE LOAN AMT. EXCEEDED' },
//         { code: '0511', text: 'MISSING LOC / INT ONLY INDICATOR' },
//         {
//             code: '0553',
//             text: 'MINIMUM LOAN TERM MUST  BE \u003e= 6 MONTHS',
//         },
//         { code: '0707', text: 'BORROWER RISK LEVEL IN ERROR' },
//         { code: '1212', text: 'FLEX100 PRODUCT NOT AVAILABLE' },
//         { code: '1213', text: 'GDS \u003c= 0' },
//         { code: '1215', text: 'TDS \u003c= 0' },
//         { code: '2026', text: 'PREVIOUS CLOSING DATE \u003c= 12 MONTHS' },
//         { code: '2029', text: 'PROPERTY SIZE TOO LARGE' },
//     ],
//     status: 'ENTERED',
// },
// });

export const subPartnersCountState = atom<SubPartnersCounts>({
    key: 'subPartnersCountState',
    default: undefined,
});

export const rateFiltersState = atomFamily({
    key: 'rateFiltersState',
    default: defaultRateFiltersSelector,
});

export const helocRateFiltersState = atomFamily({
    key: 'helocRateFiltersState',
    default: defaultHelocRateFiltersSelector,
});

export const tenantState = atom<TenantSlug>({
    key: 'tenantState',
    default: undefined,
});

export const ratesTabsState = atom<Context>({
    key: 'ratesTabsState',
    default: 'TRANSACTION',
});

export const docEditModeEnabled = atom<boolean>({
    key: 'docEditModeEnabled',
    default: false,
});

export type ApplicantDocumentWithFileId = ApplicantDocument & {
    fileId: string;
};

export const docEditSelectedDocs = atom<ApplicantDocumentWithFileId[]>({
    key: 'docEditSelectedDocs',
    default: [],
});

export const clientSegmentHasChanged = atom<boolean>({
    key: 'clientSegmentHasChanged',
    default: false,
});
