import { DateTime } from 'luxon';

import type { I18n } from '@lingui/core';

// Phone number regex
// 123-456-7890
// (123) 456-7890
// 123 456 7890
// 123.456.7890
const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// Postal code regex (see https://regex101.com/r/Dthk4g/1)
// Canadian: H1H 1H1, H1H1H1, h0h0h0
// US: 90219
// US Extended: 90219-1121
const postalCodeRegex =
    /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9])|(?:[0-9]{5}(?:-[0-9]{4})?))$/i;

export const isPostalCode =
    (i18n: I18n) =>
    (value: string): string | undefined =>
        value && !postalCodeRegex.test(value)
            ? i18n._('error.invalidPostalCode')
            : undefined;

export const isDateValid = (i18n: I18n) => (value: any) => {
    if (!value || value === '' || value === '0001-01-01T00:00:00Z')
        return undefined;

    return !DateTime.fromISO(value, { zone: 'utc' }).isValid ||
        value === '0001-01-01T00:00:00Z'
        ? i18n._('error.invalidDate')
        : undefined;
};

export const isBeforeDate = (i18n: I18n) => (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    const today = new Date().toISOString();

    return value < today ? undefined : i18n._('error.dateShouldBePast');
};

export const isTodayOrFutureDate = (i18n: I18n) => (value: any) => {
    if (typeof value !== 'string') {
        return undefined;
    }

    const today = new Date().toISOString();

    return value >= today ? undefined : i18n._('error.dateShouldBeFuture');
};

export const isDateRequired =
    (i18n: I18n, isRequired = false) =>
    (value: any) =>
        isRequired &&
        (!value || value === '' || value === '0001-01-01T00:00:00Z')
            ? i18n._('error.dateRequired')
            : undefined;

export const isBoolean = (i18n: I18n) => (value: any) =>
    ![true, false].includes(value) ? i18n._('error.invalidBoolean') : undefined;

export const isMin =
    (i18n: I18n, minNumber: number) => (value: string | number) =>
        +value < minNumber
            ? i18n._('error.valueGreaterThan', { value: minNumber })
            : undefined;

export const isMax =
    (i18n: I18n, maxNumber: number) =>
    (value: string | number): string | undefined =>
        +value > maxNumber
            ? i18n._('error.valueLowerThan', { value: maxNumber })
            : undefined;

export const isPhoneNumber =
    (i18n: I18n) =>
    (value: string): string | undefined =>
        value && !phoneNumberRegex.test(value)
            ? i18n._('error.invalidPhoneNumber')
            : undefined;

// eslint-disable-next-line no-useless-escape
const emailRegexp =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail =
    (i18n: I18n) =>
    (value: string): string | undefined =>
        value && !emailRegexp.test(value)
            ? i18n._('error.invalidEmail')
            : undefined;

export const isRequired = (i18n: I18n, required = true) => ({
    required: required ? i18n._('error.fieldRequired') : undefined,
});

export class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ValidationError';
    }
}
