import { compose } from 'utils';

import type { Step } from 'components/cmhc/types';
import type { UploadFile as OcrData } from 'types/documents';

export const validationOrder: Step[] = [
    'transaction',
    'financial',
    'property',
    'mainBorrower',
    'coBorrowers',
    'escalation',
];

export const getValidationSteps = (currentStep: Step) =>
    validationOrder.slice(0, validationOrder.indexOf(currentStep) + 1);

export const transformOcr = (ocr: OcrData, step: Step, formValues: any) => {
    const transform = (entries) =>
        entries.map(([fieldName, value]) => [
            fieldName,
            {
                ...ocr.fields[step][fieldName],
                // TODO update: newUpdateValueHere
                value,
            },
        ]);

    const transformArray = (entries) => {
        const transform = (posArr: number) => (items) =>
            items.map(([fieldName, value]) => [
                fieldName,
                {
                    ...ocr.fields[step]?.[posArr][fieldName],
                    // TODO update: newUpdateValueHere
                    value,
                },
            ]);

        // return [];
        return (entries || []).map((borrower, borrowerIndex) =>
            compose(
                // eslint-disable-next-line
                // @ts-ignore
                Object.fromEntries,
                transform(borrowerIndex),
                Object.entries
            )(borrower)
        );
    };

    // arr.reduce((acc, val) => acc.concat(val), []);
    // eslint-disable-next-line
    // @ts-ignore
    const flatten = (items: any[]) =>
        items.reduce((acc, val) => acc.concat(val), []);
    // const flatten = (items: any[]) => items.flat();

    const toOcrFields = !['coBorrowers'].includes(step)
        ? // eslint-disable-next-line
          // @ts-ignore
          compose(Object.fromEntries, transform, Object.entries)
        : // eslint-disable-next-line
          // @ts-ignore
          compose(transformArray, flatten, Object.values);

    const updatedFields = toOcrFields(formValues);

    return {
        ...ocr,
        meta: {
            ...ocr.meta,
            completedSections: getValidationSteps(step),
        },
        fields: {
            ...ocr.fields,
            [step]: updatedFields,
        },
    };
};
